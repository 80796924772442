import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ZONE_SELECTION_SLICE_NAME } from "./zoneSelectionHistory.constants";
import { ZONE_SELECTION_HISTORY_INITIAL_STATE } from "./zoneSelectionHistory.state";

export const { actions, reducer } = createSlice({
    name: ZONE_SELECTION_SLICE_NAME,
    initialState: ZONE_SELECTION_HISTORY_INITIAL_STATE,
    reducers: {
        add: (state, action: PayloadAction<unknown>) => {
            state.backwardStack.push(action.payload);
            state.forwardStack = [];
        },
        moveForward: state => {
            if (state.forwardStack.length === 0) return;

            const selection = state.forwardStack[state.forwardStack.length - 1];
            state.forwardStack = state.forwardStack.slice(0, -1);
            state.backwardStack.push(selection);
        },
        moveBackward: state => {
            if (state.backwardStack.length <= 1) return;

            const selection = state.backwardStack[state.backwardStack.length - 1];
            state.backwardStack = state.backwardStack.slice(0, -1);
            state.forwardStack.push(selection);
        },
        setInitialHistory: (state, action: PayloadAction<unknown[]>) => {
            state.backwardStack = action.payload;
            state.forwardStack = [];
        },
        clear: () => {
            return ZONE_SELECTION_HISTORY_INITIAL_STATE;
        },
    },
});

export const { add, moveForward, moveBackward, setInitialHistory, clear } = actions;

export const zoneSelectionHistoryReducer = reducer;
