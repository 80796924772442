export const MAX_SANDBOX_ZONE_COUNT = 100;
export const OSM_VINTAGE = {
    year_num: 2025,
    month_num: 1,
};
export const BUS_VINTAGE = {
    year_num: 2022,
    month_num: 10,
};
export const RAIL_VINTAGE = {
    year_num: 2022,
    month_num: 9,
};
export const ANALYSIS_TYPE_KEYWORD_SEPARATOR = ",";
export const BIKE_PED_MIN_YEAR_WITH_DATA = 2019;
