import { getAnalysisTypeCode } from "@app/analysis/state/general/general.selectors";
import {
    ZONE_ROLES,
    ZONE_TYPE_MODE,
} from "@app/analysis/zones/chooseZones/state/chooseZones.constants";
import { getSelectedZones } from "@app/analysis/zones/chooseZones/state/chooseZones.selectors";
import { getZoneSetModalState } from "@app/bigBang/zoneSetModal/state/zoneSetModal.selectors";
import { StlNotification } from "@common/components";
import { ANALYSIS_TYPES } from "@common/constants/analysis.constants";
import { ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import { ZONE_GEOM_TYPES } from "@common/constants/zoneSet.constants";
import { getIsOSMSegment } from "@common/features/zones/zones.helpers";
import { confirmGateChange } from "@common/features/zonesManager/components/confirmationDialogs/confirmationDialogs";
import { convertLineToRectangleMultiPolygon } from "@common/features/zonesManager/components/spotCounter/spotCounter.helpers.ts";
import {
    convertZonesUploadDataToApiObject,
    filterDefaultOptions,
    parseUploadFileResponse,
    validateZonesUploadData,
} from "@common/features/zonesManager/components/uploadZonesModal/screens/editUploadZones/uploadZones.helpers";
import {
    SHAPEFILE_UPLOAD_POLLING_INTERVAL_MS,
    SHAPEFILE_UPLOAD_POLLING_STATUSES,
} from "@common/features/zonesManager/components/uploadZonesModal/uploadZonesModal.constants";
import { ZONES_MANAGER_ACTIONS } from "@common/features/zonesManager/state/zonesManager.actionTypes";
import { convertApiObjectToCustomGate } from "@common/features/zonesManager/state/zonesManager.helpers";
import { convertApiObjectToZone } from "@common/features/zonesManager/state/zonesManager.helpers.temp";
import {
    convertCustomGateToApiObject,
    convertGateApiObjectToZone,
    convertZoneToApiObject,
    getCustomGateIndexName,
    getReversedDirection,
    getZoneDirectionType,
    getZoneGate,
} from "@common/features/zonesManager/state/zonesManager.helpers.ts";
import {
    getOSMSegmentsFilters,
    getReuseZonesModalState,
    getSelectedZone,
    getUiStates,
    getUploadZonesModalState,
} from "@common/features/zonesManager/state/zonesManager.selectors";
import { UploadApiService } from "@common/services/server/uploadApi.service";
import { ZonesApiService } from "@common/services/server/zonesApi.service";
import hash from "object-hash";
import { batch } from "react-redux";

import { MANAGER_MODES, ZONES_MANAGER_CONTROLS } from "./zonesManager.constants";
import { ZONES_MANAGER_INITIAL_STATE } from "./zonesManager.state";

export const resetZoneManagerState = () => ({
    type: ZONES_MANAGER_ACTIONS.RESET_ZONE_MANAGER_STATE,
});

export const resetReuseZonesModalState = () => ({
    type: ZONES_MANAGER_ACTIONS.RESET_REUSE_ZONES_MODAL_STATE,
});

export const setReuseZonesModalState = data => ({
    type: ZONES_MANAGER_ACTIONS.SET_REUSE_ZONES_MODAL_STATE,
    data,
});

export const setReuseZonesConfig = reuseZonesConfig => ({
    type: ZONES_MANAGER_ACTIONS.SET_REUSE_ZONES_CONFIG,
    data: { reuseZonesConfig },
});

export const setZoneLibraryType = zoneType => ({
    type: ZONES_MANAGER_ACTIONS.SET_ZONE_LIBRARY_TYPE,
    data: { zoneType },
});

export const setZoneLibraryFilters = zoneType => dispatch => {
    batch(() => {
        dispatch(setZoneLibraryType(zoneType));
        dispatch({
            type: ZONES_MANAGER_ACTIONS.SET_ZONE_LIBRARY_FILTERS,
            data: { zoneType },
        });
    });
};

export const setSelectedNetworkType = networkType => ({
    type: ZONES_MANAGER_ACTIONS.SET_NETWORK_TYPE,
    data: { networkType },
});

export const setSelectedRoads = roads => ({
    type: ZONES_MANAGER_ACTIONS.SET_ROADS,
    data: { roads },
});

export const setSelectedZone = zone => ({
    type: ZONES_MANAGER_ACTIONS.SET_SELECTED_ZONE,
    data: { zone },
});

export const updateSelectedZone = zone => ({
    type: ZONES_MANAGER_ACTIONS.UPDATE_SELECTED_ZONE,
    data: { zone },
});

export const setClickedBusZones = clickedBusZones => ({
    type: ZONES_MANAGER_ACTIONS.SET_CLICKED_BUS_ZONES,
    data: { clickedBusZones },
});

export const updateClickedBusZones = clickedBusZones => ({
    type: ZONES_MANAGER_ACTIONS.UPDATE_CLICKED_BUS_ZONES,
    data: { clickedBusZones },
});

export const setMapMode = mapMode => ({
    type: ZONES_MANAGER_ACTIONS.SET_MAP_MODE,
    data: { mapMode },
});

export const setEditableFeature = editableFeature => ({
    type: ZONES_MANAGER_ACTIONS.SET_EDITABLE_FEATURE,
    data: { editableFeature },
});

export const setIsEditDirection = isEditDirection => ({
    type: ZONES_MANAGER_ACTIONS.SET_IS_EDIT_DIRECTION,
    data: { isEditDirection },
});

export const setUploadZonesModalState = data => ({
    type: ZONES_MANAGER_ACTIONS.SET_UPLOAD_ZONES_MODAL_STATE,
    data,
});

export const updateZonesUploadData = zonesUploadData => ({
    type: ZONES_MANAGER_ACTIONS.UPDATE_ZONES_UPLOAD_DATA,
    data: { zonesUploadData },
});

export const setUiState = data => ({
    type: ZONES_MANAGER_ACTIONS.SET_UI_STATE,
    data,
});

export const setSetSpotCounterOsmLayersCategories = data => ({
    type: ZONES_MANAGER_ACTIONS.SET_SPOT_COUNTER_OSM_LAYERS_CATEGORIES,
    data,
});

export const closeZoneEditing = (zoneMode, mapMode) => (dispatch, getState) => {
    const { segmentSplitType } = getOSMSegmentsFilters(getState());

    const activeControl =
        zoneMode === ZONE_TYPE_MODE.ZONES.id || !zoneMode
            ? ZONES_MANAGER_CONTROLS.ZONE_TYPES_PICKER
            : ZONES_MANAGER_CONTROLS.SPOT_COUNTER;

    batch(() => {
        dispatch(setUiState({ activeControl }));
        dispatch(setEditableFeature(null));
        dispatch(setMapMode(mapMode || MANAGER_MODES.VIEW_MAP));
        dispatch(setSelectedZone(null));
        // need to preserve 'segmentSplitType' value when reset to default state
        dispatch(
            setZoneLibraryType({
                ...ZONES_MANAGER_INITIAL_STATE.zoneLibraryType,
                filters: { segmentSplitType },
            }),
        );
    });
};

export const fetchZoneArea = ({ zone, zoneGeometry }) => {
    const data = {
        poly_geom: JSON.stringify(zoneGeometry),
    };

    return ZonesApiService.getZoneArea(data).then(res => {
        return {
            ...zone,
            area: res.area,
            feature: {
                geometry: zoneGeometry,
            },
        };
    });
};

export const fetchNewGates = ({ zone, editableFeature }) => {
    const data = {
        line_geom: JSON.stringify(editableFeature?.geometry),
        road_type: zone.road_type,
    };

    return ZonesApiService.createZoneGates(data).then(res => {
        return {
            ...zone,
            ...convertGateApiObjectToZone(res.data),
            feature: {
                geometry: editableFeature?.geometry,
            },
        };
    });
};

export const createNewGates = (newZoneFeature, selectedZone) => (dispatch, getState) => {
    const state = getState();
    const zone = getSelectedZone(state);
    const { editableFeature } = getUiStates(state);

    const _editableFeature = newZoneFeature || editableFeature;
    const _zone = zone || selectedZone;

    dispatch(
        updateSelectedZone({
            ..._zone,
            featureStart: null,
            featureMiddle: null,
            featureEnd: null,
        }),
    );

    return fetchNewGates({ zone: _zone, editableFeature: _editableFeature }).then(updatedZone => {
        dispatch(setSelectedZone(updatedZone));

        return updatedZone;
    });
};

export const validSpotCounterZone = (polyGeom, spotCounterProperties) => {
    const data = {
        poly_geom: JSON.stringify(polyGeom.geometry),
        zone_kind_id:
            spotCounterProperties.osm_segment_zone_kind_id || spotCounterProperties.zone_kind_id,
        zone_id: parseInt(
            spotCounterProperties.osm_segment_zone_id || spotCounterProperties.zone_id,
            10,
        ),
    };

    return ZonesApiService.getSpotCounterGateDirection(data);
};

export const createSpotCounterZone =
    ({ zone, newZoneFeature, onSaveNewZone }) =>
    dispatch =>
        onSaveNewZone(zone, newZoneFeature).then(() => {
            StlNotification.success("Gate has been successfully created");
            dispatch(
                closeZoneEditing(
                    ZONE_TYPE_MODE.SPOT_COUNTERS.id,
                    MANAGER_MODES.PLACE_SPOT_COUNTER,
                ),
            );
        });

export const updateSpotCounter =
    ({ zone, onUpdateZone, newZoneFeature }) =>
    (dispatch, getState) => {
        const state = getState();
        const { editableFeature } = getUiStates(state);

        const _editableFeature = newZoneFeature || editableFeature;

        const zoneInputs = convertCustomGateToApiObject(zone, _editableFeature);

        return onUpdateZone(zone, zoneInputs)
            .then(updatedZone => {
                const _zone = convertApiObjectToCustomGate(updatedZone);

                return Promise.resolve(_zone);
            })
            .then(updatedZone => {
                StlNotification.success("Gate has been successfully updated");

                batch(() => {
                    dispatch(setSelectedZone(updatedZone));
                    dispatch(setMapMode(MANAGER_MODES.VIEW_ZONE));
                    dispatch(setEditableFeature(null));
                    dispatch(closeZoneEditing());
                });
            });
    };

export const saveNewZone =
    ({ onSaveNewZone, zone, isGeometryChanged, newZoneFeature, isLibraryZoneUpdate = false }) =>
    (dispatch, getState) => {
        const state = getState();
        const { editableFeature } = getUiStates(state);
        const isLineZone = zone.geom_type === ZONE_GEOM_TYPES.LINE.id;
        const isOSMSegment = getIsOSMSegment(zone);

        const _editableFeature =
            isOSMSegment && !isGeometryChanged
                ? newZoneFeature
                : newZoneFeature || editableFeature;

        const promise =
            isGeometryChanged && isLineZone && !isOSMSegment
                ? dispatch(createNewGates(newZoneFeature, zone))
                : Promise.resolve(zone);

        return promise
            .then(zoneToSave => onSaveNewZone(zoneToSave, _editableFeature))
            .then(() => {
                StlNotification.success(
                    `Zone has been successfully ${isLibraryZoneUpdate ? "updated" : "created"}.`,
                );

                dispatch(closeZoneEditing());
            });
    };

export const updateZone =
    ({ zone, onUpdateZone, isGeometryChanged, newZoneFeature }) =>
    (dispatch, getState) => {
        const state = getState();
        const { editableFeature } = getUiStates(state);

        const isPolygonZone = zone.geom_type === ZONE_GEOM_TYPES.POLYGON.id;
        const isLineZone = zone.geom_type === ZONE_GEOM_TYPES.LINE.id;
        const isOSMSegment = getIsOSMSegment(zone);

        const _editableFeature =
            !isGeometryChanged && isOSMSegment
                ? newZoneFeature
                : newZoneFeature || editableFeature;

        const zoneInputs = convertZoneToApiObject(zone, _editableFeature);

        const promise =
            isGeometryChanged && isLineZone && !isOSMSegment
                ? dispatch(createNewGates(newZoneFeature, zone))
                : Promise.resolve(zone);

        return promise
            .then(zoneToUpdate => onUpdateZone(zoneToUpdate, zoneInputs))
            .then(updatedZone => {
                const _zone = convertApiObjectToZone(updatedZone);

                if (isGeometryChanged && isPolygonZone) {
                    return fetchZoneArea({
                        zone: _zone,
                        zoneGeometry: _zone.feature?.geometry,
                    });
                }

                return Promise.resolve(_zone);
            })
            .then(updatedZone => {
                batch(() => {
                    dispatch(setSelectedZone(updatedZone));
                    dispatch(setMapMode(MANAGER_MODES.VIEW_ZONE));
                    dispatch(setEditableFeature(null));
                });
            });
    };

export const updateGate =
    ({ zone, onUpdateGate, onSaveNewZone }) =>
    (dispatch, getState) => {
        const state = getState();
        const { editableFeature } = getUiStates(state);
        const zoneInputs = convertZoneToApiObject(zone, editableFeature);
        // when gate is edited on a new line zone, store its geometry without sending request
        if (!zone.zone_id) {
            const updatedZone = {
                ...zone,
                [`feature${zone.gate}`]: {
                    geometry: editableFeature.geometry,
                },
            };

            batch(() => {
                dispatch(setSelectedZone(updatedZone));
                dispatch(setEditableFeature(null));
                dispatch(setMapMode(MANAGER_MODES.VIEW_ZONE));
            });

            return Promise.resolve();
        }

        // when gate is edited on immutable zone or on zone with multiple roles, need to create a new zone
        if (zone?.is_immutable || zone?.used_with_multiple_roles) {
            return onSaveNewZone(zone, editableFeature).then(createdZone => {
                batch(() => {
                    dispatch(setSelectedZone(convertApiObjectToZone(createdZone)));
                    dispatch(setEditableFeature(null));
                    dispatch(setMapMode(MANAGER_MODES.VIEW_ZONE));
                });
            });
        }

        return onUpdateGate(zone, zoneInputs).then(updatedZone => {
            batch(() => {
                dispatch(setSelectedZone(convertApiObjectToZone(updatedZone)));
                dispatch(setEditableFeature(null));
                dispatch(setMapMode(MANAGER_MODES.VIEW_ZONE));
            });
        });
    };

export const reverseLineZone = (zone, onReverseLineZone) => () =>
    onReverseLineZone(zone).then(data => {
        const reversedZone = {
            ...zone,
            s_geom_direction: getReversedDirection(zone.e_geom_direction),
            e_geom_direction: getReversedDirection(zone.s_geom_direction),
            direction: getReversedDirection(zone.direction),
            start_geometry: zone.end_geometry,
            end_geometry: zone.start_geometry,
            line_geometry: data.zone?.line_geometry || data.line_geom,
        };

        return convertApiObjectToZone(reversedZone);
    });

export const saveDrawnZone =
    (drawInstance, onUpdateZone, onSaveNewZone) => (dispatch, getState) => {
        if (!drawInstance) return Promise.resolve();

        const state = getState();
        const zone = getSelectedZone(state);
        const { mapMode, editableFeature, isEditDirection } = getUiStates(state);

        if (isEditDirection) return Promise.resolve();

        const removeDrawFeatures = () => {
            drawInstance.deleteAll();
            drawInstance.changeMode("static");
        };
        const currentZoneCoords = zone?.zone_id ? zone.feature.geometry.coordinates : {};

        if (hash(currentZoneCoords) === hash(editableFeature.geometry.coordinates)) {
            removeDrawFeatures();

            batch(() => {
                dispatch(setMapMode(MANAGER_MODES.VIEW_ZONE));
                dispatch(setEditableFeature(null));
            });

            return Promise.resolve();
        }

        const isLineZone = zone.geom_type === ZONE_GEOM_TYPES.LINE.id;

        // Only zone geometry is drawn, no zone properties provided, calculate gates and length or area and save them
        // to the selected zone, on the server zone will be created after Save btn click
        if (!zone?.zone_id) {
            const isCreateMode = [MANAGER_MODES.DRAW_POLYGON, MANAGER_MODES.DRAW_LINE].includes(
                mapMode,
            );

            let newMapMode, shouldCreateNewGates;
            if (isCreateMode) {
                newMapMode = isLineZone ? MANAGER_MODES.VIEW_ZONE : MANAGER_MODES.EDIT_POLYGON;
                shouldCreateNewGates = isLineZone && mapMode === MANAGER_MODES.DRAW_LINE;
            } else {
                newMapMode = MANAGER_MODES.VIEW_ZONE;
                shouldCreateNewGates = isLineZone && mapMode === MANAGER_MODES.EDIT_LINE;
            }

            batch(() => {
                if (shouldCreateNewGates) {
                    dispatch(createNewGates());
                }
                if (!isLineZone) {
                    fetchZoneArea({ zone, zoneGeometry: editableFeature?.geometry }).then(
                        updatedZone => {
                            dispatch(setSelectedZone(updatedZone));
                        },
                    );
                }
                dispatch(updateSelectedZone({ feature: editableFeature }));
                dispatch(setMapMode(newMapMode));
                dispatch(setEditableFeature(null));
                dispatch(
                    setUiState({
                        activeControl: isLineZone
                            ? ZONES_MANAGER_CONTROLS.LINE_ZONE
                            : ZONES_MANAGER_CONTROLS.POLYGON_ZONE,
                    }),
                );
            });

            return Promise.resolve();
        } else if (mapMode === MANAGER_MODES.EDIT_LINE_GATE) {
            dispatch(setEditableFeature(null));
            // we don't have such case at the moment
            throw new Error("Saving gates after drawing is not supported");
        } else {
            // There were changes to zone geometry (either line or polygon), which should be saved
            const _state = getState();
            const _zone = getSelectedZone(_state);

            // create new zone if it is immutable or was already used with a different role (applicable for Create
            // Analysis) because we don't want to make changes to both zones and a new zone should be created
            if (_zone?.is_immutable || editableFeature.properties.with_multiple_roles) {
                const promise =
                    zone.geom_type === ZONE_GEOM_TYPES.LINE.id
                        ? dispatch(createNewGates())
                        : Promise.resolve(_zone);

                return promise
                    .then(zoneToSave => onSaveNewZone(zoneToSave, editableFeature))
                    .then(createdZone => {
                        const updatedZone = convertApiObjectToZone(createdZone);

                        if (zone.geom_type === ZONE_GEOM_TYPES.POLYGON.id) {
                            return fetchZoneArea({
                                zone: updatedZone,
                                zoneGeometry: updatedZone.feature?.geometry,
                            });
                        }

                        return Promise.resolve(updatedZone);
                    })
                    .then(updatedZone => {
                        batch(() => {
                            dispatch(setSelectedZone(updatedZone));
                            dispatch(setMapMode(MANAGER_MODES.VIEW_ZONE));
                            dispatch(setEditableFeature(null));
                        });
                    });
            }

            return dispatch(
                updateZone({ zone: _zone, onUpdateZone, isGeometryChanged: true }),
            ).then(removeDrawFeatures);
        }
    };

export const savePlacedCustomGate =
    (zoneData, lineFeature, intersectionFeature) => (dispatch, getState) => {
        if (!lineFeature) return;

        const state = getState();
        const zone = getSelectedZone(state);
        const selectedZones = getSelectedZones(state);

        const currentGateCoordinates =
            zone?.zone_id && zone.feature ? zone.feature.geometry.coordinates : [];

        if (hash(currentGateCoordinates) === hash(lineFeature.geometry.coordinates)) {
            batch(() => {
                dispatch(setMapMode(MANAGER_MODES.EDIT_SPOT_COUNTER));
                dispatch(setEditableFeature(lineFeature));
            });

            return;
        }

        batch(() => {
            dispatch(
                updateSelectedZone({
                    ...zone,
                    direction: zoneData.direction,
                    direction_type: getZoneDirectionType(zoneData),
                    is_pass: zoneData.is_pass,
                    is_bidi: zoneData.is_bidi,
                    feature: convertLineToRectangleMultiPolygon(lineFeature, 1),
                    point: zoneData.lngLat,
                    zone_name: intersectionFeature
                        ? getCustomGateIndexName(
                              selectedZones[ZONE_ROLES.ORIGINS.accessor],
                              zoneData.zone_name,
                          )
                        : zone.zone_name,
                    osm_segment_zone_id: parseInt(
                        zoneData.osm_segment_zone_id || zoneData.zone_id,
                        10,
                    ),
                    osm_segment_zone_kind_id:
                        zoneData.osm_segment_zone_kind_id || zoneData.zone_kind_id,
                    intersection: intersectionFeature || zone.intersection,
                }),
            );
            dispatch(setMapMode(MANAGER_MODES.EDIT_SPOT_COUNTER));
            dispatch(setEditableFeature(lineFeature));
        });
    };

export const selectZoneOnMap = () => (dispatch, getState) => {
    const state = getState();
    const zone = getSelectedZone(state);
    const { isEditDirection } = getUiStates(state);

    if (!zone.feature || isEditDirection) return;

    const editMode =
        zone.geom_type === ZONE_GEOM_TYPES.LINE.id
            ? MANAGER_MODES.EDIT_LINE
            : MANAGER_MODES.EDIT_POLYGON;

    batch(() => {
        dispatch(setEditableFeature(zone.feature));
        dispatch(setMapMode(editMode));
    });
};

export const selectGateOnMap = id => (dispatch, getState) => {
    if (!id) return;

    const state = getState();
    const zone = getSelectedZone(state);
    const { editableFeature, isEditDirection } = getUiStates(state);

    if (isEditDirection) return;

    const gateId = id[0].toUpperCase() + id.slice(1);

    const editGate = () => {
        const updatePrevGateDirections = {
            [getZoneGate(gateId).directionProperty]: zone[getZoneGate(gateId).directionProperty],
        };

        const gateData = { gate: gateId, prevGateDirections: updatePrevGateDirections };

        const updatedGateData = zone.prevGateDirections
            ? { ...gateData, ...zone.prevGateDirections }
            : gateData;

        batch(() => {
            dispatch(updateSelectedZone(updatedGateData));
            dispatch(setEditableFeature(zone[`feature${gateId}`]));
            dispatch(setMapMode(MANAGER_MODES.EDIT_LINE_GATE));
        });
    };

    if (editableFeature) {
        confirmGateChange(editGate);
        return;
    }

    editGate();
};

export const createCustomGatesFromLineZones = (selectedZones, onAddExistingZones) => {
    const { customGates, lineZones } = selectedZones.reduce(
        (result, zone) => {
            if (zone.zone_kind_id === ZONE_KINDS.GATE.id[0]) {
                result.customGates.push(zone);
            } else {
                result.lineZones.push({
                    zone_id: zone.zone_id,
                    zone_kind_id: zone.zone_kind_id,
                });
            }

            return result;
        },
        { customGates: [], lineZones: [] },
    );

    if (!lineZones.length) return onAddExistingZones(selectedZones);

    const data = {
        zone_inputs: lineZones,
    };

    return ZonesApiService.bulkCreateCustomGatesFromLineZones(data).then(({ zones }) =>
        onAddExistingZones([...customGates, ...zones]),
    );
};

export const addExistingZones = onAddExistingZones => (dispatch, getState) => {
    const state = getState();
    const analysisTypeCode = getAnalysisTypeCode(state);
    const { selectedZones } = getReuseZonesModalState(state);

    // Remove duplicate zones
    const _selectedZones = selectedZones.reduce((res, zone) => {
        const hasZone = !!res.find(_zone => _zone.zone_id === zone.zone_id);
        if (hasZone) {
            return res;
        }

        return [...res, zone];
    }, []);

    dispatch(setReuseZonesModalState({ isLoading: true }));

    const promise =
        analysisTypeCode === ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.id
            ? createCustomGatesFromLineZones(_selectedZones, onAddExistingZones)
            : onAddExistingZones(_selectedZones);

    return promise.finally(() => dispatch(setReuseZonesModalState({ isLoading: false })));
};

export const pollUploadStatus = (to, uuid) => dispatch => {
    return UploadApiService.pollFileUploadStatus(to, uuid)
        .then(_res => {
            if (
                _res.status === SHAPEFILE_UPLOAD_POLLING_STATUSES.PROCESSING ||
                _res.status === SHAPEFILE_UPLOAD_POLLING_STATUSES.NEW
            ) {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        return dispatch(pollUploadStatus(to, uuid)).then(resolve).catch(reject);
                    }, [SHAPEFILE_UPLOAD_POLLING_INTERVAL_MS]);
                });
            } else if (_res.status === SHAPEFILE_UPLOAD_POLLING_STATUSES.AVAILABLE) {
                StlNotification.success("File has been successfully uploaded.");
                return Promise.resolve();
            }

            return Promise.reject();
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export const uploadShapefile = (files, zonesLimit) => (dispatch, getState) => {
    const state = getState();
    const analysisTypeCode = getAnalysisTypeCode(state);
    const isLineZone =
        analysisTypeCode === ANALYSIS_TYPES.SEGMENT.id &&
        analysisTypeCode === ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id;

    const params = {
        fileFormDataName: "shapefile",
        data: {
            ...(isLineZone && { geom_type: "L" }),
            ...(zonesLimit && { max_zone_count: zonesLimit }),
        },
    };

    return UploadApiService.uploadFile("zone_shapefile", files, params).then(res => {
        return dispatch(pollUploadStatus("zone_shapefile", res.zone_shapefile_uuid)).then(() => {
            return UploadApiService.getFileMappings(
                "zone_shapefile",
                res.zone_shapefile_uuid,
            ).then(_res => {
                const parsedData = parseUploadFileResponse(_res, {
                    uuid: res.zone_shapefile_uuid,
                });

                dispatch(setUploadZonesModalState({ zonesUploadData: parsedData }));
            });
        });
    });
};

export const saveUploadedZones = onUploadSuccess => (dispatch, getState) => {
    const { zoneSet } = getZoneSetModalState(getState());
    const { zonesUploadData } = getUploadZonesModalState(getState());

    const validation = validateZonesUploadData(zonesUploadData, true);

    if (validation.length) {
        const error = {
            message: `Please select ${validation.join(", ")}.`,
            isValidation: true,
        };
        return Promise.reject(error);
    }

    const data = convertZonesUploadDataToApiObject({ zoneSet, zonesUploadData });
    const fieldChoicesValidation = filterDefaultOptions(data.field_choices);

    if (fieldChoicesValidation.length) {
        const error = {
            message: `Please select ${fieldChoicesValidation.join(", ")}.`,
            isValidation: true,
        };
        return Promise.reject(error);
    }

    return onUploadSuccess(data);
};
