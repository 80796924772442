import { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import { StlButton } from "@common/components";
import {
    DEFAULT_TOOL,
    TZoneSelectionSelectedTool,
    TZoneSelectionSelectedToolId,
    ZONE_SELECTION_HISTORY_ACTIONS,
    ZONE_SELECTION_HISTORY_ACTIONS_LIST,
    ZONE_SELECTION_TOOLS,
    ZONE_SELECTION_TOOLS_LIST,
} from "../zoneSelectionTools.constants";
import { IZoneSelectionHistory } from "./useZoneSelectionControl/useSelectionHistory";
import "./selectionToolsComponent.less";

type TProps<T extends unknown> = {
    history: IZoneSelectionHistory<T>;
    selectedToolId: TZoneSelectionSelectedToolId;
    onToolSelect: (tool: TZoneSelectionSelectedToolId) => void;
    onUpdateZoneSelection?: (zoneSelection: T) => void;
    disableSelectionTools?: boolean;
};

const LassoToolsComponent = ({
    disableSelectionTools,
    selectedToolId,
    onToolSelect,
}: {
    disableSelectionTools?: boolean;
    selectedToolId: TZoneSelectionSelectedToolId;
    onToolSelect: (tool: TZoneSelectionSelectedToolId) => void;
}) => {
    const [showToolsModal, setShowToolsModal] = useState(false);

    const zoneSelectionTool = useRef<TZoneSelectionSelectedTool>(ZONE_SELECTION_TOOLS.CURSOR);

    const toggleModalState = () => {
        setShowToolsModal(prevState => !prevState);
    };

    const selectTool = (toolId?: TZoneSelectionSelectedToolId) => {
        if (!toolId) {
            onToolSelect(DEFAULT_TOOL.id);
            return;
        }

        toggleModalState();
        onToolSelect(toolId);

        zoneSelectionTool.current =
            ZONE_SELECTION_TOOLS_LIST.find(tool => tool.id === toolId) ||
            ZONE_SELECTION_TOOLS.CURSOR;
    };

    return (
        <div className="lasso-selection-tools-wrapper">
            <StlButton
                ariaLabel={zoneSelectionTool.current.name}
                title="Zone Selection Tools"
                onClick={toggleModalState}
                className={classNames(
                    "lasso-tool",
                    disableSelectionTools && "disabled",
                    zoneSelectionTool.current.id === selectedToolId && "selected",
                )}
            >
                <img
                    src={
                        zoneSelectionTool.current.id === selectedToolId
                            ? zoneSelectionTool.current.img.selected
                            : zoneSelectionTool.current.img.unselected
                    }
                    alt={zoneSelectionTool.current.name}
                />
            </StlButton>
            <StlButton
                ariaLabel={DEFAULT_TOOL.name}
                title={DEFAULT_TOOL.title}
                onClick={() => selectTool()}
                className={classNames(
                    "lasso-tool",
                    disableSelectionTools && "disabled",
                    DEFAULT_TOOL.id === selectedToolId && "selected",
                )}
            >
                <img
                    src={
                        DEFAULT_TOOL.id === selectedToolId
                            ? DEFAULT_TOOL.img.selected
                            : DEFAULT_TOOL.img.unselected
                    }
                    alt={DEFAULT_TOOL.name}
                />
            </StlButton>
            {showToolsModal && (
                <div className="selection-tools">
                    <div className="title">Zone Selection Tools</div>
                    <div className="tools">
                        {ZONE_SELECTION_TOOLS_LIST.map(tool => (
                            <StlButton
                                key={tool.id}
                                ariaLabel={tool.name}
                                title={tool.title}
                                onClick={() => selectTool(tool.id)}
                                className={classNames(
                                    "lasso-tool",
                                    disableSelectionTools && "disabled",
                                )}
                            >
                                <img src={tool.img.unselected} alt={tool.name} />
                            </StlButton>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const HistoryToolsComponent = <T extends unknown>({
    history,
    onUpdateZoneSelection,
}: {
    history: IZoneSelectionHistory<T>;
    onUpdateZoneSelection?: (zoneSelection: T) => void;
}) => {
    const handleNavigateHistoryStack = useCallback(
        (isForward: boolean) => {
            const zoneSelectionFromHistory = isForward
                ? history.moveForward()
                : history.moveBackward();

            if (onUpdateZoneSelection) onUpdateZoneSelection(zoneSelectionFromHistory);
        },
        [onUpdateZoneSelection, history],
    );

    return (
        <>
            {ZONE_SELECTION_HISTORY_ACTIONS_LIST.map(tool => {
                const isForward = tool.id === ZONE_SELECTION_HISTORY_ACTIONS.HISTORY_REDO.id;

                const { isDisabled, handleClick } = {
                    handleClick: () => handleNavigateHistoryStack(isForward),
                    isDisabled: isForward
                        ? !history.isForwardNavigationEnabled()
                        : !history.isBackwardNavigationEnabled(),
                };

                return (
                    <StlButton
                        key={tool.id}
                        ariaLabel={tool.name}
                        title={tool.title}
                        aria-disabled={isDisabled}
                        onClick={isDisabled ? undefined : handleClick}
                        className={classNames("lasso-tool", isDisabled && "disabled")}
                    >
                        <img src={tool.img.unselected} alt={tool.name} />
                    </StlButton>
                );
            })}
        </>
    );
};

// TODO: Move LassoToolsComponent and HistoryToolsComponent to separate files
// TODO: Move map related logic to LassoToolsComponent
export const SelectionToolsComponent = <T extends unknown>({
    onToolSelect,
    history,
    onUpdateZoneSelection,
    selectedToolId = ZONE_SELECTION_TOOLS.CURSOR.id,
    disableSelectionTools = false,
}: TProps<T>) => (
    <>
        <LassoToolsComponent
            onToolSelect={onToolSelect}
            selectedToolId={selectedToolId}
            disableSelectionTools={disableSelectionTools}
        />
        <HistoryToolsComponent history={history} onUpdateZoneSelection={onUpdateZoneSelection} />
    </>
);
