import { getApiAnalysesIds } from "@common/helpers/analysis";
import {
    IAdminResponse,
    IBulkActionAnalysesData,
    IBulkActionAnalysesResponse,
    IBulkCreateUpdateUsersData,
    IBulkCreateUpdateUsersResponse,
    ICreateOrgAPIKeyData,
    ICreateOrgAPIKeyResponse,
    ICreateOrgResponse,
    ICreateUserData,
    ICreateUserResponse,
    IDeleteAPIKeyConfig,
    IEmulateUserData,
    IExportCSVMAAPIRequestsData,
    IExportCsvMAAPIRequestsResponse,
    IGetAdminDashboardResponse,
    IGetAdminRoleResponse,
    IGetAdminStudiesResponse,
    IGetAutoPassedZonesAnalysesResponse,
    IGetAvailableOrgsResponse,
    IGetBuildInfoResponse,
    IGetFeatureBundlesResponse,
    IGetFeaturesResponse,
    IGetMAAPIRequestResponse,
    IGetMAAPIRequestsResponse,
    IGetMetricPackagesResponse,
    IGetOrgAPIKeysResponse,
    IGetOrgResponse,
    IGetOrgsResponse,
    IGetOrgSubscriptionsResponse,
    IGetOrgUsersResponse,
    IGetOrgUsersUpdateResponse,
    IGetProjectInfoResponse,
    IGetProjectResponse,
    IGetSSODomainsResponse,
    IGetStudyAnalysesResponse,
    IGetStudyPurchaseResponse,
    IGetStudyUnusedZoneSetsResponse,
    IGetSubscriptionResponse,
    IGetUserOrgsUpdateResponse,
    IGetUserResponse,
    IGetUsersResponse,
    IMAAPIRequestData,
    IOperationsSubscriptionData,
    IOrganizationData,
    IOrgUsersUpdateResponse,
    IRemoveAutoPassCoverageReviewData,
    IRemoveUserFromOrgResponse,
    ISafetyPrioritizeSubscriptionData,
    ISSODomainData,
    ISubscriptionData,
    IUpdateMassiveQueueAnalysesData,
    IUpdateOrgAPIKeyData,
    IUpdateOrgAPIKeyResponse,
    IUpdateOrgResponse,
    IUpdateStudyPurchaseData,
    IUpdateUserResponse,
    TAddOrgToUserData,
    TBulkUpdateOrgUsersData,
    TBulkUpdateUserOrgsData,
    TUpdateOrgUserData,
    TUpdateUserData,
} from "@common/services/server/adminApi.types";
import { HttpService, IRequestConfig } from "@common/services/server/http.service";

export const AdminApiService = {
    getBuildInfo() {
        return HttpService.get<IGetBuildInfoResponse>("/admin/build_info");
    },
    getProjectInfo(webapp_project_id: number, ds_project_id: number) {
        return HttpService.get<IGetProjectInfoResponse>(
            `/admin/project/${webapp_project_id}/${ds_project_id}`,
        );
    },
    getProjects() {
        return HttpService.get<IGetProjectResponse>("/admin/project");
    },
    pauseAnalyses(analyses: Array<IBulkActionAnalysesData>) {
        const analysesIds = getApiAnalysesIds(analyses);

        return HttpService.post<IBulkActionAnalysesResponse>("/admin/project/pausing", {
            project_details: analysesIds,
        });
    },
    resumeAnalyses(analyses: Array<IBulkActionAnalysesData>) {
        const analysesIds = getApiAnalysesIds(analyses);

        return HttpService.post<IBulkActionAnalysesResponse>("/admin/project/resuming", {
            project_details: analysesIds,
        });
    },
    updateMassiveQueueAnalyses({ analyses, isMassiveQueue }: IUpdateMassiveQueueAnalysesData) {
        const analysesIds = getApiAnalysesIds(analyses);

        return HttpService.post<IBulkActionAnalysesResponse>("/admin/project/massive_queue", {
            project_details: analysesIds,
            is_massive_queue: isMassiveQueue,
        });
    },
    getFeatures() {
        return HttpService.get<IGetFeaturesResponse>("/admin/feature").catch(
            HttpService.showIfError("Unknown error occurred while fetching admin features list."),
        );
    },
    getFeatureBundles() {
        return HttpService.get<IGetFeatureBundlesResponse>("/admin/feature_bundle").catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching admin feature bundles list.",
            ),
        );
    },
    getOrgAPIKeys(orgId: number) {
        return HttpService.get<IGetOrgAPIKeysResponse>(
            `/admin/organization/${orgId}/api_key`,
        ).catch(HttpService.showIfError("Unknown error occurred while fetching org API keys."));
    },
    createOrgAPIKey(orgId: number, data: ICreateOrgAPIKeyData) {
        return HttpService.post<ICreateOrgAPIKeyResponse>(
            `/admin/organization/${orgId}/api_key`,
            data,
        )
            .then(HttpService.showIfSuccess("API Key has been successfully created."))
            .catch(HttpService.showIfError("Unknown error occurred while creating API Key."));
    },
    updateOrgAPIKey(orgId: number, apiKeyId: number, data: IUpdateOrgAPIKeyData) {
        return HttpService.put<IUpdateOrgAPIKeyResponse>(
            `/admin/organization/${orgId}/api_key/${apiKeyId}`,
            data,
        )
            .then(HttpService.showIfSuccess("API Key has been successfully updated."))
            .catch(HttpService.showIfError("Unknown error occurred while updating API key."));
    },
    bulkUpdateOrgUsers(
        orgId: number,
        dataToUpdate: "roles" | "effective_date" | "end_date" | "user_category",
        data: TBulkUpdateOrgUsersData,
    ) {
        return HttpService.post<IGetOrgUsersUpdateResponse>(
            `/admin/organization/${orgId}/bulk_action/${dataToUpdate}`,
            data,
        )
            .then(HttpService.showIfSuccess("Account users have been successfully updated."))
            .catch(
                HttpService.showIfError("Unknown error occurred while updating account users."),
            );
    },
    deleteOrgAPIKey(orgId: number, apiKeyId: number, config: IRequestConfig<IDeleteAPIKeyConfig>) {
        return HttpService.delete<IAdminResponse>(
            `/admin/organization/${orgId}/api_key/${apiKeyId}`,
            config,
        );
    },
    getOrgSubscriptions(orgId: number) {
        return HttpService.get<IGetOrgSubscriptionsResponse>(
            `/admin/subscription_list/${orgId}`,
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching org subscriptions list.",
            ),
        );
    },
    getSubscription(subscriptionId: number) {
        return HttpService.get<IGetSubscriptionResponse>(
            `/admin/subscription/${subscriptionId}`,
        ).catch(HttpService.showIfError("Unknown error occurred while fetching subscription."));
    },
    createSubscription(subscription: ISubscriptionData) {
        return HttpService.post<IAdminResponse>("/admin/subscription", subscription)
            .then(HttpService.showIfSuccess("Subscription has been successfully created."))
            .catch(HttpService.showIfError("Unknown error occurred while creating subscription."));
    },
    updateSubscription(subscriptionId: number, data: ISubscriptionData) {
        return HttpService.put<IAdminResponse>(`/admin/subscription/${subscriptionId}`, data)
            .then(HttpService.showIfSuccess("Subscription has been successfully updated."))
            .catch(HttpService.showIfError("Unknown error occurred while updating subscription."));
    },
    deleteSubscription(subscriptionId: number) {
        return HttpService.delete<IAdminResponse>(`/admin/subscription/${subscriptionId}`);
    },
    getOperationsSubscription(subscriptionId: number) {
        return HttpService.get<IGetSubscriptionResponse>(
            `/admin/operations_subscription/${subscriptionId}`,
        ).catch(HttpService.showIfError("Unknown error occurred while fetching subscription."));
    },
    createOperationsSubscription(subscription: IOperationsSubscriptionData) {
        return HttpService.post<IAdminResponse>("/admin/operations_subscription", subscription)
            .then(HttpService.showIfSuccess("Subscription has been successfully created."))
            .catch(HttpService.showIfError("Unknown error occurred while creating subscription."));
    },
    updateOperationsSubscription(subscriptionId: number, data: IOperationsSubscriptionData) {
        return HttpService.put<IAdminResponse>(
            `/admin/operations_subscription/${subscriptionId}`,
            data,
        )
            .then(HttpService.showIfSuccess("Subscription has been successfully updated."))
            .catch(HttpService.showIfError("Unknown error occurred while updating subscription."));
    },
    deleteOperationsSubscription(subscriptionId: number) {
        return HttpService.delete<IAdminResponse>(
            `/admin/operations_subscription/${subscriptionId}`,
        );
    },
    getSafetyPrioritizeSubscription(subscriptionId: number) {
        return HttpService.get<IGetSubscriptionResponse>(
            `/admin/safety_prioritize_subscription/${subscriptionId}`,
        ).catch(HttpService.showIfError("Unknown error occurred while fetching subscription."));
    },
    createSafetyPrioritizeSubscription(subscription: ISafetyPrioritizeSubscriptionData) {
        return HttpService.post<IAdminResponse>(
            "/admin/safety_prioritize_subscription",
            subscription,
        )
            .then(HttpService.showIfSuccess("Subscription has been successfully created."))
            .catch(HttpService.showIfError("Unknown error occurred while creating subscription."));
    },
    updateSafetyPrioritizeSubscription(
        subscriptionId: number,
        data: ISafetyPrioritizeSubscriptionData,
    ) {
        return HttpService.put<IAdminResponse>(
            `/admin/safety_prioritize_subscription/${subscriptionId}`,
            data,
        )
            .then(HttpService.showIfSuccess("Subscription has been successfully updated."))
            .catch(HttpService.showIfError("Unknown error occurred while updating subscription."));
    },
    deleteSafetyPrioritizeSubscription(subscriptionId: number) {
        return HttpService.delete<IAdminResponse>(
            `/admin/safety_prioritize_subscription/${subscriptionId}`,
        );
    },
    getMetricsPackages() {
        return HttpService.get<IGetMetricPackagesResponse>("/admin/metrics_package").catch(
            HttpService.showIfError("Unknown error occurred while fetching metrics packages."),
        );
    },
    getUsers() {
        return HttpService.get<IGetUsersResponse>("/admin/user");
    },
    updateUser(id: number, body: TUpdateUserData) {
        return HttpService.put<IUpdateUserResponse>(`/admin/user/${id}`, body);
    },
    bulkUpdateUserOrgs(
        userId: number,
        dataToUpdate: "roles" | "effective_date" | "end_date" | "user_category",
        data: TBulkUpdateUserOrgsData,
    ) {
        return HttpService.post<IGetUserOrgsUpdateResponse>(
            `/admin/user/${userId}/bulk_action/${dataToUpdate}`,
            data,
        )
            .then(HttpService.showIfSuccess("User accounts have been successfully updated."))
            .catch(
                HttpService.showIfError("Unknown error occurred while updating user accounts."),
            );
    },
    bulkCreateUpdateUsers(orgId: number, data: IBulkCreateUpdateUsersData) {
        return HttpService.post<IBulkCreateUpdateUsersResponse>(
            `/admin/organization/${orgId}/users/bulk_create_update`,
            data,
        )
            .then(HttpService.showIfSuccess("Bulk user upload task has been successfully queued."))
            .catch(HttpService.showIfError("Unknown error occurred during bulk user upload."));
    },
    saveUser(body: ICreateUserData) {
        return HttpService.post<ICreateUserResponse>("/admin/user", body);
    },
    getUser(id: number) {
        return HttpService.get<IGetUserResponse>(`/admin/user/${id}`);
    },
    addOrgToUser(userId: number, orgId: number, data: TAddOrgToUserData) {
        return HttpService.post<IUpdateUserResponse>(
            `/admin/user/${userId}/organisation/${orgId}`,
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while adding account for user."));
    },
    getOrgs() {
        return HttpService.get<IGetOrgsResponse>("/admin/organization").catch(
            HttpService.showIfError("Unknown error occurred while fetching orgs list."),
        );
    },
    getOrg(orgId: number) {
        return HttpService.get<IGetOrgResponse>(`/admin/organization/${orgId}`).catch(
            HttpService.showIfError("Unknown error occurred while fetching org data."),
        );
    },
    getOrgUsers(orgId: number) {
        return HttpService.get<IGetOrgUsersResponse>(`/admin/organization/${orgId}/users`);
    },
    updateOrgUser(orgId: number, userId: number, data: TUpdateOrgUserData) {
        return HttpService.put<IOrgUsersUpdateResponse>(
            `/admin/organization/${orgId}/user/${userId}`,
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while updating org's user."));
    },
    removeUserFromOrg(orgId: number, userId: number) {
        return HttpService.put<IRemoveUserFromOrgResponse>(
            `/admin/organization/${orgId}/remove_user_from_org/${userId}`,
        ).catch(HttpService.showIfError("Unknown error occurred while removing user from org."));
    },
    createOrg(org: IOrganizationData) {
        return HttpService.post<ICreateOrgResponse>("/admin/organization", org)
            .then(HttpService.showIfSuccess(`Org ${org.org_name} has been successfully created.`))
            .catch(
                HttpService.showIfError(
                    `Unknown error occurred while creating org ${org.org_name}.`,
                ),
            );
    },
    updateOrg(orgId: number, data: IOrganizationData) {
        return HttpService.put<IUpdateOrgResponse>(`/admin/organization/${orgId}`, data)
            .then(
                HttpService.showIfSuccess(
                    `Org ${data.org_name || ""} has been successfully updated.`,
                ),
            )
            .catch(
                HttpService.showIfError(
                    `Unknown error occurred while updating org ${data.org_name}.`,
                ),
            );
    },
    getAvailableOrgs() {
        return HttpService.get<IGetAvailableOrgsResponse>("/admin/available_orgs");
    },
    getAdminRole() {
        return HttpService.get<IGetAdminRoleResponse>("/admin/role").catch(
            HttpService.showIfError("Unknown error occurred while fetching admin roles list."),
        );
    },
    getAdminStudies() {
        return HttpService.get<IGetAdminStudiesResponse>("/admin/study").catch(
            HttpService.showIfError("Unknown error occurred while fetching studies."),
        );
    },
    getStudyPurchase(studyId: number) {
        return HttpService.get<IGetStudyPurchaseResponse>(
            `/admin/study_purchase_request/${studyId}`,
        ).catch(
            HttpService.showIfError("Unknown error occurred while making study purchase request."),
        );
    },
    updateStudyPurchase(studyId: number, data: IUpdateStudyPurchaseData) {
        return HttpService.put<IAdminResponse>(
            `/admin/study_purchase_request/${studyId}`,
            data,
        ).catch(
            HttpService.showIfError(
                `Unknown error occurred while creating org for study with ID ${studyId}.`,
            ),
        );
    },
    getAdminDashboard() {
        return HttpService.get<IGetAdminDashboardResponse>("/admin_dashboard").catch(
            HttpService.showIfError("Unknown error occurred while fetching Admin Dashboard."),
        );
    },
    getStudyUnusedZoneSets(studyId: number) {
        return HttpService.get<IGetStudyUnusedZoneSetsResponse>(
            `/admin/study/unused_zone_set/${studyId}`,
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while getting unused zone sets for study.",
            ),
        );
    },
    copyStudyAnalysesAndZoneSets(studyName: string, studyId: number, targetOrgId: number) {
        return HttpService.post<IAdminResponse>(
            `/admin/study/copy_to_org/${studyId}/${targetOrgId}`,
        )
            .then(
                HttpService.showIfSuccess(
                    `Successfully copied analyses and zone sets for study ${studyName}.`,
                ),
            )
            .catch(
                HttpService.showIfError(
                    `Unknown error occurred while copying analyses and zone sets for study ${studyName}.`,
                ),
            );
    },
    getStudyAnalyses(studyId: number) {
        return HttpService.get<IGetStudyAnalysesResponse>(
            `/admin/study/projects/${studyId}`,
        ).catch(
            HttpService.showIfError("Unknown error occurred while getting analyses for study."),
        );
    },
    getAutoPassedZonesAnalyses(orgId: number) {
        return HttpService.get<IGetAutoPassedZonesAnalysesResponse>(
            `/admin/auto_passed_project/${orgId}`,
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching analyses with auto-passed zones.",
            ),
        );
    },
    removeAutoPassCoverageReview(data: IRemoveAutoPassCoverageReviewData) {
        return HttpService.put<IAdminResponse>("/admin/zone/remove_auto_pass", data)
            .then(HttpService.showIfSuccess("Successfully removed auto-pass coverage review."))
            .catch(
                HttpService.showIfError(
                    "Unknown error occurred while removing auto-pass coverage review.",
                ),
            );
    },
    emulateUser(params: IEmulateUserData) {
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", "/server/emulate");
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const input = document.createElement("input");
                const param = params[key as keyof typeof params];
                input.setAttribute("type", "hidden");
                input.setAttribute("name", key);
                input.setAttribute("value", String(param));
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        form.submit();
    },
    endEmulation() {
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", "/server/stop_emulate");
        document.body.appendChild(form);
        form.submit();
    },
    getSSODomains() {
        return HttpService.get<IGetSSODomainsResponse>("/admin/sso_domain").catch(
            HttpService.showIfError("Unknown error occurred while fetching SSO domains."),
        );
    },
    createSSODomain(ssoDomain: ISSODomainData) {
        return HttpService.post<IAdminResponse>("/admin/sso_domain", ssoDomain)
            .then(
                HttpService.showIfSuccess(
                    `Domain ${ssoDomain.domain_name} has been successfully created.`,
                ),
            )
            .catch(
                HttpService.showIfError(
                    `Unknown error occurred while creating domain ${ssoDomain.domain_name}.`,
                ),
            );
    },
    updateSSODomain(ssoDomainId: number, data: ISSODomainData) {
        return HttpService.put<IAdminResponse>(`/admin/sso_domain/${ssoDomainId}`, data)
            .then(
                HttpService.showIfSuccess(
                    `Domain ${data.domain_name} has been successfully updated.`,
                ),
            )
            .catch(
                HttpService.showIfError(
                    `Unknown error occurred while updating domain ${data.domain_name}.`,
                ),
            );
    },
    deleteSSODomain(ssoDomainId: number) {
        return HttpService.delete<IAdminResponse>(`/admin/sso_domain/${ssoDomainId}`)
            .then(HttpService.showIfSuccess("Domain has been successfully deleted."))
            .catch(HttpService.showIfError("Unknown error occurred while deleting domain."));
    },
    getMAAPIRequests() {
        return HttpService.get<IGetMAAPIRequestsResponse>("/admin/ma_api/request").catch(
            HttpService.showIfError("Unknown error occurred while fetching MA API requests."),
        );
    },
    getMAAPIRequest(requestId: number) {
        return HttpService.get<IGetMAAPIRequestResponse>(
            `/admin/ma_api/request/${requestId}`,
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while fetching MA API request details.",
            ),
        );
    },
    updateMAAPIRequest(requestId: number, data: IMAAPIRequestData) {
        return HttpService.put<IAdminResponse>(`/admin/ma_api/request/${requestId}`, data)
            .then(HttpService.showIfSuccess("MA API request has been successfully updated."))
            .catch(
                HttpService.showIfError("Unknown error occurred while updating MA API request."),
            );
    },
    exportCsvMAAPIRequests(data: IExportCSVMAAPIRequestsData) {
        return HttpService.post<IExportCsvMAAPIRequestsResponse>(
            "/admin/ma_api/download",
            data,
        ).catch(HttpService.showIfError("Unknown error occurred while exporting CSV file"));
    },
};
