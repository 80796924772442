import { createSelector } from "reselect";
import { TRootState } from "@app/store";

export const getZoneSelectionHistory = (state: TRootState) => state.zoneSelectionHistory;

export const getCurrentSelection = createSelector(
    getZoneSelectionHistory,
    zoneSelectionHistory => {
        const { backwardStack } = zoneSelectionHistory;
        return backwardStack[backwardStack.length - 1];
    },
);

export const getIsForwardNavigationEnabled = createSelector(
    getZoneSelectionHistory,
    (zoneSelectionHistory): boolean => {
        return zoneSelectionHistory.forwardStack.length > 0;
    },
);

export const getIsBackwardNavigationEnabled = createSelector(
    getZoneSelectionHistory,
    (zoneSelectionHistory): boolean => {
        return zoneSelectionHistory.backwardStack.length > 1;
    },
);
