import { setUserAnalysisDefaults } from "@app/analysis/state/analysisConfiguration.actions";
import { IS_APP_INITIATED } from "@app/app";
import { AUTH_TYPES } from "@app/auth/auth.constants";
import { getServerRedirectUrl, getSSOAuth, isSSOFlow } from "@app/auth/state/auth.helpers";
import { isAbsoluteUrl, storeBaseProductUrl } from "@app/store/currentUser/currentUser.helpers";
import {
    fetchAnalysesTypeKeywords,
    fetchAvailableDataPeriods,
    fetchSATCAvailableDataPeriods,
    fetchZoneVintageDates,
} from "@app/store/staticData/state/staticData.actions";
import { userPreferencesInitialize } from "@app/store/userPreferences/userPreferences.actions";
import { PLATFORM_ACCESS_TYPES } from "@common/constants/orgTypes.constants";
import { ROUTES } from "@common/constants/routes.constants";
import { IS_REDIRECTED, LAST_ACCESSED_PRODUCT } from "@common/helpers/navigation.helpers";
import { AnalyticsTrack } from "@common/services/analytics.service";
import { esriService } from "@common/services/esri.service";
import { heapAnalyticsService } from "@common/services/heapAnalytics.service";
import { history } from "@common/services/history.service";
import { loggerService } from "@common/services/logger.service";
import { HttpService } from "@common/services/server/http.service";
import { OrgApiService } from "@common/services/server/orgApi.service";
import { UserApiService } from "@common/services/server/userApi.service";
import { walkmeService } from "@common/services/walkme.service";
import { XSRFToken } from "@common/services/XSRFToken.service";
import { isEqual } from "lodash-es";
import { batch } from "react-redux";

import { CURRENT_USER_ACTIONS } from "./currentUser.actionTypes";
import {
    ORG_ID_KEY,
    POLL_ORG_BALANCE_INTERVAL_MS,
    REDIRECT_KEY,
    REDIRECT_URL_KEY,
} from "./currentUser.constants";
import {
    currentUserDataToState,
    getIsUserAuthenticated,
    isAuthRequired,
    orgBalanceDataToState,
} from "./currentUser.helpers";
import { getCurrentUser, getIsOrgHasFeature, getUserRedirectPath } from "./currentUser.selector";

let orgBalanceTimeout;

export const setCurrentUser = currentUser => ({
    type: CURRENT_USER_ACTIONS.SET_CURRENT_USER,
    data: { currentUser },
});

export const setCurrentOrgBalance = orgBalance => ({
    type: CURRENT_USER_ACTIONS.SET_ORG_BALANCE,
    data: { orgBalance },
});

export const setSSOEnabled = isSSOEnabled => ({
    type: CURRENT_USER_ACTIONS.SET_SSO_ENABLED,
    data: { isSSOEnabled },
});

export const setProducts = products => ({
    type: CURRENT_USER_ACTIONS.SET_PRODUCTS,
    data: { products },
});

export const fetchOrgBalance = () => (dispatch, getState) => {
    const state = getState();

    const { orgBalance, user } = getCurrentUser(state);

    if (orgBalanceTimeout) {
        window.clearTimeout(orgBalanceTimeout);
    }

    const orgHasUniqueZoneQuota = user.org?.unique_zone_quota !== null;
    const isInsightGoOnlyOrg =
        user.org?.product_access_types?.length === 1 &&
        !!user.org.product_access_types.includes(PLATFORM_ACCESS_TYPES.LITE.value);
    const shouldDisplayUniqueZoneCount =
        user.org?.is_study_enabled &&
        user.study?.unique_zone_count !== null &&
        getIsOrgHasFeature(state, "display_unique_zone_count");

    if (orgHasUniqueZoneQuota || shouldDisplayUniqueZoneCount || isInsightGoOnlyOrg) {
        return OrgApiService.getCurrentOrgBalance()
            .then(res => {
                const updatedOrgBalance = orgBalanceDataToState(res.org_balance);

                if (!isEqual(updatedOrgBalance, orgBalance)) {
                    dispatch(setCurrentOrgBalance(updatedOrgBalance));
                }
            })
            .catch(HttpService.silentError)
            .finally(() => {
                orgBalanceTimeout = window.setTimeout(
                    () => dispatch(fetchOrgBalance()),
                    POLL_ORG_BALANCE_INTERVAL_MS,
                );
            });
    }

    return Promise.resolve();
};

export const fetchCurrentUser = () => dispatch => {
    if (!getIsUserAuthenticated()) {
        if (isAuthRequired(window.location.hash)) {
            history.push("/login");
        }

        return Promise.reject({ handled: true });
    }

    return UserApiService.getCurrentUser().then(res => {
        const currentUser = currentUserDataToState(res);

        heapAnalyticsService.setUserIdentify(currentUser.realUser.user_id);

        batch(() => {
            dispatch(setCurrentUser(currentUser));
            dispatch(userPreferencesInitialize(currentUser.user.preferences));
            dispatch(setUserAnalysisDefaults());
        });

        if (currentUser.user.org) {
            AnalyticsTrack.registerSuperProp(currentUser);
            heapAnalyticsService.addUserProperties(currentUser);
            walkmeService.addWalkmeVariables(currentUser);
            loggerService.addUserProperties(currentUser);
            const { insight_full_subscriptions, insight_lite_subscriptions } =
                currentUser.user.org;
            const { isUAFSigned, isSuper } = currentUser;

            if (
                (insight_full_subscriptions?.length || insight_lite_subscriptions?.length) &&
                (isUAFSigned || isSuper)
            ) {
                // fetchOrgBalance() request should be called after UAF is signed to prevent user from being logged out
                batch(() => {
                    dispatch(fetchOrgBalance());
                    dispatch(fetchAvailableDataPeriods());
                    dispatch(fetchSATCAvailableDataPeriods());
                    dispatch(fetchZoneVintageDates());
                    dispatch(fetchAnalysesTypeKeywords());
                });
            }
        }

        return currentUser;
    });
};

export const setUserOrg =
    ({ selectedOrgId, useSetProductOrg = false }) =>
    dispatch => {
        const orgApi = useSetProductOrg ? OrgApiService.setProductOrg : OrgApiService.setOrg;
        return orgApi({ org_id: selectedOrgId })
            .then(setOrgRes => {
                // User needs to sign the powerform in order to be fully authenticated.
                if (setOrgRes?.powerform_url) {
                    window.location.assign(setOrgRes.powerform_url);
                    return null;
                }

                return Promise.allSettled([
                    UserApiService.getCurrentUser(),
                    UserApiService.getProducts(),
                ]).then(results => {
                    const currentUser = currentUserDataToState(results[0].value);
                    dispatch(setCurrentUser(currentUser));
                    if (currentUser.user.org) {
                        walkmeService.addWalkmeVariables(currentUser);
                    }
                    if (isSSOFlow()) {
                        const serverRedirectUrl = getServerRedirectUrl();
                        storeBaseProductUrl(serverRedirectUrl);
                        history.push(serverRedirectUrl);
                        history.go(0);
                        return;
                    }

                    const products = results[1].value.data?.products || [];
                    dispatch(setProducts(products));
                    sessionStorage.removeItem(IS_APP_INITIATED);
                    if (window.location.search.includes(ORG_ID_KEY)) {
                        history.push(window.location.pathname);
                    } else if (currentUser.user.org?.is_study_enabled) {
                        if (products.length > 1) {
                            history.push(`${ROUTES.CHOOSE_PRODUCT}?isFromStudyOrg=true`);
                        } else {
                            history.push(ROUTES.CHOOSE_STUDY);
                        }
                    } else if (products.length > 1) {
                        history.push(ROUTES.CHOOSE_PRODUCT);
                    } else {
                        const selectedProduct = products.find(
                            product =>
                                product?.product_type !== PLATFORM_ACCESS_TYPES.INSIGHT.value,
                        );
                        const selectedProductUrl = selectedProduct
                            ? selectedProduct?.product_url
                            : ROUTES.DEFAULT;
                        sessionStorage.setItem(LAST_ACCESSED_PRODUCT, selectedProductUrl);
                        localStorage.setItem(LAST_ACCESSED_PRODUCT, selectedProductUrl);

                        if (selectedProductUrl !== ROUTES.DEFAULT) {
                            window.location.href = selectedProductUrl;
                            sessionStorage.setItem(IS_REDIRECTED, "true");
                            return;
                        } else {
                            history.push(ROUTES.DEFAULT);
                        }
                    }

                    history.go(0);
                });
            })
            .catch(error => {
                return Promise.reject(error);
            });
    };

export const setUserStudy = studyId => (dispatch, getState) => {
    return OrgApiService.setStudy({ study_id: studyId })
        .then(() => {
            return UserApiService.getCurrentUser();
        })
        .then(res => {
            const currentUser = currentUserDataToState(res);
            dispatch(setCurrentUser(currentUser));
            return dispatch(fetchCurrentUser());
        })
        .then(() => {
            sessionStorage.removeItem(IS_APP_INITIATED);
            history.push(getUserRedirectPath(getState()));
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export const fireLogin = params => (dispatch, getState) => {
    let productCount = 0;

    return UserApiService.login(params)
        .then(res => {
            XSRFToken.set(res.authtoken);
            sessionStorage.removeItem(IS_APP_INITIATED);
            if (res.powerform_url) {
                window.location.assign(res.powerform_url);
                return null;
            } else {
                return dispatch(fetchCurrentUser())
                    .then(() => UserApiService.getProducts())
                    .then(result => {
                        const products = result?.data.products;
                        dispatch(setProducts(products));
                        productCount = products?.length;

                        const serverRedirectUrl = getServerRedirectUrl();
                        if (serverRedirectUrl) {
                            const isOldHashRouting = Boolean(window.location.hash.length > 1);
                            const ssoRedirectUrl = window.location.origin + serverRedirectUrl;

                            if (isOldHashRouting) {
                                window.location.href = ssoRedirectUrl;
                                return;
                            }
                            storeBaseProductUrl(serverRedirectUrl);
                            history.push(serverRedirectUrl);
                            history.go(0);
                            return;
                        }

                        const userRedirectPath = getUserRedirectPath(getState());
                        const url =
                            (userRedirectPath !== ROUTES.CHOOSE_ORG ||
                                userRedirectPath !== ROUTES.CHOOSE_STUDY) &&
                            productCount > 1
                                ? ROUTES.CHOOSE_PRODUCT
                                : userRedirectPath;
                        const redirectPath = window.location.search.split(REDIRECT_KEY)[1] || url;

                        if (productCount === 1) {
                            const selectedProduct = products.find(
                                product =>
                                    product?.product_type !== PLATFORM_ACCESS_TYPES.INSIGHT.value,
                            );
                            const selectedProductUrl = selectedProduct
                                ? selectedProduct?.product_url
                                : ROUTES.DEFAULT;
                            sessionStorage.setItem(LAST_ACCESSED_PRODUCT, selectedProductUrl);
                            localStorage.setItem(LAST_ACCESSED_PRODUCT, selectedProductUrl);
                        }

                        if (isAbsoluteUrl(redirectPath)) {
                            sessionStorage.setItem(IS_REDIRECTED, "true");
                            window.location.href = redirectPath;
                        } else {
                            history.push(redirectPath);
                        }
                    });
            }
        })
        .catch(error => {
            // If error wasn't handled in errors interceptor.
            if (!error.handled) {
                XSRFToken.remove();
            }
            return Promise.reject(error);
        });
};

export const login = (email, password) => dispatch => {
    const params = {
        email_address: email,
        password,
        auth_type: AUTH_TYPES.PASSWORD,
        sso_auth: getSSOAuth(),
    };

    return dispatch(fireLogin(params));
};

export const msSSOLogin = (authResult, email) => dispatch => {
    const params = {
        email_address: email,
        auth_type: AUTH_TYPES.MICROSOFT,
        id_token: authResult.idToken,
        access_token: authResult.accessToken,
    };

    return dispatch(fireLogin(params));
};

export const logout = () => () => {
    return UserApiService.logout().then(() => {
        sessionStorage.removeItem(REDIRECT_URL_KEY);
        heapAnalyticsService.resetUserIdentify();
        walkmeService.resetWalkmeVariables();
        XSRFToken.remove();
        esriService.signout();
        history.push("/login");
        history.go(0);
    });
};
