import { TProductType } from "@common/services/server/userApi.types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faRoad,
    faRoadBarrier,
    faTrafficCone,
    faTrafficLight,
} from "@fortawesome/pro-solid-svg-icons";

export const EXIT_SANDBOX_PATH = "/exit-sandbox";

export const AUTH_TYPES = {
    PASSWORD: "Password",
    MICROSOFT: "Microsoft",
} as const;

export const PRODUCT_DETAILS: Record<
    TProductType,
    { description: string; image: IconProp; route: string }
> = {
    insight_full: {
        description:
            " A self-serve platform with analysis tools, comprehensive transportation metrics, and visualizations to get insights into vehicle, truck, bicycle, and pedestrian travel.",
        image: faTrafficLight,
        route: "/",
    },
    insight_lite: {
        description: "",
        image: faTrafficLight,
        route: "/go",
    },
    safety_prioritize: {
        description:
            "Custom dashboards that can include crash data, equity layers, multimodal volumes, countermeasure treatments, and more, for localized safety insights to prioritize improvements.",
        image: faTrafficCone,
        route: "/safety-prioritize",
    },
    traffic_monitor: {
        description:
            "Compare real-time disruptions to typical conditions to monitor traffic on all roads with an always-on dashboard featuring travel time, volumes, and speeds.",
        image: faRoadBarrier,
        route: "https://traffic_monitor.streetlightdata.com/",
    },
    construction: {
        description:
            "Pinpoint and mitigate disruptions in construction zones with real-time and historical traffic data for immediate insights across your entire network.",
        image: faRoad,
        route: "https://construction.streetlightdata.com/",
    },
};
