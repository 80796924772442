import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { GeoJsonProperties } from "geojson";
import type { Map } from "mapbox-gl";
import {
    EControlPositions,
    TSelectionToolsConfig,
} from "@common/components/baseMap/baseMap.types";
import { SelectionToolsControl } from "@common/components/baseMap/customControls/selectionToolsControl";
import { SelectionToolsComponent } from "@common/components/baseMap/customControls/zoneSelectionTools/baseMapControl/selectionToolsComponent";
import { useFreeSelection } from "@common/components/baseMap/customControls/zoneSelectionTools/baseMapControl/useZoneSelectionControl/useFreeSelection";
import { useSelectionHistory } from "@common/components/baseMap/customControls/zoneSelectionTools/baseMapControl/useZoneSelectionControl/useSelectionHistory";
import {
    TZoneSelectionSelectedToolId,
    ZONE_SELECTION_TOOLS,
} from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";
import { useDidMount } from "@common/hooks/useDidMount";
import { useWillUnmount } from "@common/hooks/useWillUnmount";
import { arrayIncludes } from "@root/src/common/utils/arrayIncludes";
import { IZoneSelectionHistory } from "./useSelectionHistory";
import { getIsLineLayers, getLayerIds } from "./useZoneSelectionControl.helpers";

export type TZoneSelectionTools = {
    historyStack: IZoneSelectionHistory<unknown>;
    selectedToolId: TZoneSelectionSelectedToolId | null;
    setSelectedToolId: (tool: TZoneSelectionSelectedToolId | null) => void;
};

export type TSelectableZoneLayerIds = {
    selected: string[];
    unselected: string[];
};

export type TSelectableZoneLayers = {
    line: TSelectableZoneLayerIds;
    polygon: TSelectableZoneLayerIds;
};

export type TProps<T> = TSelectionToolsConfig<T>;

export const useZoneSelectionControl = <T extends unknown>(
    map: Map | null,
    {
        layers,
        zoneTypeId,
        initialHistory,
        showSelectionTools = true,
        disableSelectionTools = false,
        position = EControlPositions.TOP_RIGHT,
    }: TProps<T>,
) => {
    const [selectedToolId, setSelectedToolId] = useState<TZoneSelectionSelectedToolId>(
        ZONE_SELECTION_TOOLS.CURSOR.id,
    );

    const history = useSelectionHistory<T>();

    useDidMount(() => {
        if (!initialHistory) return;

        history.setInitialHistory(initialHistory);
    });

    useWillUnmount(() => {
        history.clear();
    });

    const isLassoAddMode = selectedToolId === ZONE_SELECTION_TOOLS.LASSO_ADD.id;
    const isLassoRemoveMode = selectedToolId === ZONE_SELECTION_TOOLS.LASSO_REMOVE.id;

    const layerIds = useMemo(() => {
        if (!showSelectionTools || !zoneTypeId || !layers) return [];

        const _layers = getLayerIds(zoneTypeId, layers);

        if (isLassoAddMode) return _layers.unselected;

        if (isLassoRemoveMode) return _layers.selected;

        return [];
    }, [layers, zoneTypeId, isLassoAddMode, isLassoRemoveMode, showSelectionTools]);

    const toolsControl = useMemo(() => new SelectionToolsControl(), []);

    const handleToolSelect = useCallback(
        (tool: TZoneSelectionSelectedToolId) => {
            if (disableSelectionTools) return;

            setSelectedToolId(tool);
        },
        [disableSelectionTools],
    );

    const onUpdateZoneSelection = useCallback(
        selection => {
            map?.fire("zoneSelectionTools.zonesSelectionChange", { selection });
        },
        [map],
    );

    const onLassoSelection = useCallback(
        (selectedZones: GeoJsonProperties[]) => {
            if (
                !map ||
                !arrayIncludes(
                    [ZONE_SELECTION_TOOLS.LASSO_ADD.id, ZONE_SELECTION_TOOLS.LASSO_REMOVE.id],
                    selectedToolId,
                )
            ) {
                return;
            }

            map.fire("zoneSelectionTools.lassoSelection", { selectedZones, selectedToolId });
        },
        [map, selectedToolId],
    );

    useEffect(() => {
        if (!map || !toolsControl || !showSelectionTools) return undefined;

        map.addControl(toolsControl, position);

        return () => {
            map.removeControl(toolsControl);
            setSelectedToolId(ZONE_SELECTION_TOOLS.CURSOR.id);
        };
    }, [map, toolsControl, position, showSelectionTools]);

    useFreeSelection({
        map,
        layerIds,
        selectedTool: selectedToolId,
        isLineLayers: getIsLineLayers(zoneTypeId),
        onSelection: onLassoSelection,
    });

    useEffect(() => {
        if (disableSelectionTools) setSelectedToolId(ZONE_SELECTION_TOOLS.CURSOR.id);
    }, [disableSelectionTools]);

    const mountSelectionTools = useCallback(() => {
        if (!toolsControl.toolsContainer || !layers) return null;

        return ReactDOM.createPortal(
            <SelectionToolsComponent
                selectedToolId={selectedToolId}
                onToolSelect={handleToolSelect}
                history={history}
                onUpdateZoneSelection={onUpdateZoneSelection}
                disableSelectionTools={disableSelectionTools}
            />,
            toolsControl.toolsContainer as HTMLDivElement,
        );
    }, [
        handleToolSelect,
        onUpdateZoneSelection,
        toolsControl.toolsContainer,
        selectedToolId,
        layers,
        disableSelectionTools,
        history,
    ]);

    return {
        mountSelectionTools,
        zoneSelectionTools: {
            selectedToolId,
            setSelectedToolId,
            historyStack: history,
        } as TZoneSelectionTools,
    };
};
