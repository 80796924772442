import { useEffect } from "react";
import { GeoJsonProperties } from "geojson";
import { uniqBy } from "lodash-es";
import type { Map } from "mapbox-gl";
import { DRAWN_POLYGON_SELECTION_FILTERS } from "@common/components/baseMap/customControls/zoneSelectionTools/baseMapControl/useZoneSelectionControl/useZoneSelectionControl.constants";
import { getCursorType } from "@common/components/baseMap/customControls/zoneSelectionTools/baseMapControl/useZoneSelectionControl/useZoneSelectionControl.helpers";
import { useRegionDraw } from "@common/components/baseMap/customControls/zoneSelectionTools/hooks/useRegionDraw";
import { TZoneSelectionSelectedToolId } from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";
import bbox from "@turf/bbox";

type TFreeSelectionProps = {
    map: Map | null;
    layerIds: string[];
    isLineLayers?: boolean;
    selectedTool: TZoneSelectionSelectedToolId | null;
    onSelection: (segments: Array<GeoJsonProperties>) => void;
};

export const useFreeSelection = ({
    map,
    layerIds,
    selectedTool,
    isLineLayers = false,
    onSelection,
}: TFreeSelectionProps) => {
    const { removeRegion, region } = useRegionDraw({ map, selectedTool });

    useEffect(() => {
        if (
            !map ||
            !selectedTool ||
            !map.getCanvas()?.style?.cursor ||
            getCursorType(selectedTool) === map.getCanvas().style.cursor
        )
            return;

        map.getCanvas().style.cursor = getCursorType(selectedTool);
    }, [map, selectedTool]);

    useEffect(() => {
        if (!map || !region) return;

        const drawnPolygonBbox = bbox(region);

        const topMinPoint = map.project([drawnPolygonBbox[0], drawnPolygonBbox[1]]);
        const topMaxPoint = map.project([drawnPolygonBbox[2], drawnPolygonBbox[3]]);

        if (!layerIds.length) {
            removeRegion();
            return;
        }

        const layers = layerIds.filter(layer => map.getLayer(layer));
        const segmentsWithinBBox = map.queryRenderedFeatures([topMinPoint, topMaxPoint], {
            layers,
        });

        const filterFunction = isLineLayers
            ? DRAWN_POLYGON_SELECTION_FILTERS.LINE
            : DRAWN_POLYGON_SELECTION_FILTERS.POLYGON;

        const uniqSegmentsWithinBBox = uniqBy(filterFunction(segmentsWithinBBox, region), "id");

        removeRegion();

        if (!uniqSegmentsWithinBBox.length) return;

        onSelection(uniqSegmentsWithinBBox.map(({ properties }) => properties));
    }, [region, map, selectedTool, removeRegion, onSelection, layerIds, isLineLayers]);
};
