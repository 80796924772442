import { useMemo } from "react";
import { store } from "@app/store";
import { useAppDispatch } from "@app/store/hooks";
import {
    add,
    clear,
    getCurrentSelection,
    getIsBackwardNavigationEnabled,
    getIsForwardNavigationEnabled,
    moveBackward,
    moveForward,
    setInitialHistory,
} from "../state";

export interface IZoneSelectionHistory<T> {
    isForwardNavigationEnabled: () => boolean;
    isBackwardNavigationEnabled: () => boolean;
    add: (selection: T) => void;
    moveForward: () => T;
    moveBackward: () => T;
    setInitialHistory: (history: T[]) => void;
    clear: () => void;
}

export const useSelectionHistory = <T = unknown>() => {
    const dispatch = useAppDispatch();

    return useMemo<IZoneSelectionHistory<T>>(() => {
        return {
            isForwardNavigationEnabled() {
                return getIsForwardNavigationEnabled(store.getState());
            },
            isBackwardNavigationEnabled() {
                return getIsBackwardNavigationEnabled(store.getState());
            },
            add(selection: T) {
                dispatch(add(selection));
            },
            moveForward(): T {
                dispatch(moveForward());
                return getCurrentSelection(store.getState()) as T;
            },
            moveBackward(): T {
                dispatch(moveBackward());
                return getCurrentSelection(store.getState()) as T;
            },
            setInitialHistory(history: T[]) {
                dispatch(setInitialHistory(history));
            },
            clear() {
                dispatch(clear());
            },
        };
    }, [dispatch]);
};
